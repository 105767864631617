import { useMemo } from "react";

import moment from "moment";
import { useSubscriptions } from "./useSubscriptions";
import { DienstSource, DienstStatus } from "../constants";
import { useAdjustments } from "./useAdjustments";
import { checkDienstenMonth } from "../helpers/checkDienstenMonth";

const parseDateString = (str) => moment(str, ["DD-MM-YY", "DD-MM-YYYY", "YYYY-MM-DD"]);

const getSubscriptionDienstenForFamily = (
	familyId,
	subscriptions,
	firstOfFromMonth,
	lastOfToMonth,
	adjustments
) => {
	const subscriptionsForFamily = subscriptions[familyId].subscriptions;

	// Add diensten from subscriptions
	let familyDiensten = [];
	let duplicateCheck = {};
	for (let i = subscriptionsForFamily?.length - 1; i >= 0; i--) {
		const subscription = subscriptionsForFamily[i];

		let from = firstOfFromMonth;
		let to = lastOfToMonth;

		const startDateMoment = parseDateString(subscription.startDate);
		if (startDateMoment.isValid()) {
			if (startDateMoment.isAfter(lastOfToMonth)) {
				// Will start after this month has ended
				continue;
			} else if (startDateMoment.isAfter(firstOfFromMonth)) {
				// Will start this month
				from = startDateMoment;
			}
		}

		const endDateMoment = parseDateString(subscription.endDate);
		if (endDateMoment.isValid()) {
			if (endDateMoment.isBefore(firstOfFromMonth)) {
				// Has ended before this month has started
				continue;
			} else if (endDateMoment.isBefore(lastOfToMonth)) {
				// Has ended this month
				to = endDateMoment;
			}
		}

		for (let curdate = from.clone(); curdate.isSameOrBefore(to); curdate.add(1, "days")) {
			const weekDay = curdate.isoWeekday() - 1;
			if (!subscription.days[weekDay]) continue;

			let startTime, endTime;
			if (subscription.times?.[weekDay]?.from && subscription.times?.[weekDay]?.to) {
				startTime = subscription.times[weekDay].from;
				endTime = subscription.times[weekDay].to;
			}

			const oldId = `${curdate.format("YYYYMMDD")}_${familyId}_${
				subscription.nannyId || "NONANNY"
			}`;

			const id = `${curdate.format("YYYYMMDD")}_${familyId}_${
				subscription.nannyId || "NONANNY"
			}_${startTime}_${endTime}`;

			if (duplicateCheck[id]) {
				console.debug("Duplicate dienst found: ", id);
				continue;
			}

			duplicateCheck[id] = true;

			let dienst = {
				id,
				oldId,
				date: curdate.format("YYYY-MM-DD"),
				familyId,
				source: DienstSource.Subscription,
				displayDate: curdate.format("LL (dd)"),
				nannyId: subscription.nannyId,
				status: subscription.nannyId ? DienstStatus.Confirmed : DienstStatus.Open
			};

			if (startTime) {
				dienst.startTime = startTime;
			}

			if (endTime) {
				dienst.endTime = endTime;
			}

			familyDiensten.push(dienst);
		}
	}

	// Apply adjustments
	const adjustmentsForFamily = adjustments.filter((adj) => adj.familyId === familyId);
	for (let i = 0; i < adjustmentsForFamily.length; i++) {
		const adjustment = adjustmentsForFamily[i];

		let matchOnKeys = ["nannyId", "startTime", "endTime"];
		let maxMatchingKeys = 0;
		let matchingDienstIdx = -1;
		for (let j = 0; j < familyDiensten.length; j++) {
			const dienst = familyDiensten[j];
			if (dienst.date !== adjustment.date) continue;
			if (dienst.familyId !== adjustment.familyId) continue;

			let matchingKeys = 0;
			for (let k = 0; k < matchOnKeys.length; k++) {
				let key = matchOnKeys[k];
				if (dienst[key] === adjustment[key]) {
					matchingKeys++;
				}
			}

			if (matchingDienstIdx < 0 || matchingKeys > maxMatchingKeys) {
				maxMatchingKeys = matchingKeys;
				matchingDienstIdx = j;
			}

			if (maxMatchingKeys === matchOnKeys.length) {
				break;
			}
		}

		if (!familyId.includes("TEST") && matchingDienstIdx < 0) {
			console.error("Could not find matching dienst for adjustment", adjustment);
			console.error("Family diensten", familyDiensten);
			continue;
		}

		const { updateTimestamp, ...rest } = adjustment.adjustment;

		familyDiensten[matchingDienstIdx] = {
			...familyDiensten[matchingDienstIdx],
			adjustmentId: adjustment.id,
			...rest
		};

		if (updateTimestamp) {
			familyDiensten[matchingDienstIdx].updateTimestamp = updateTimestamp.toDate();
		}
	}

	return familyDiensten;
};

export const useSubscriptionDiensten = (familyId, fromMonthStr, toMonthStr) => {
	const subscriptions = useSubscriptions();

	const { loading, adjustments } = useAdjustments(familyId, fromMonthStr, toMonthStr);

	const diensten = useMemo(() => {
		if (!subscriptions) return [];
		if (!fromMonthStr) return [];
		if (!toMonthStr) return [];

		// This family has no subscriptions
		if (familyId && !subscriptions[familyId]) return [];

		let fromMonth = moment(fromMonthStr, "YYYY_MM");
		if (!fromMonth.isValid()) return [];

		let toMonth = moment(toMonthStr, "YYYY_MM");
		if (!toMonth.isValid()) return [];

		if (!checkDienstenMonth(adjustments, fromMonthStr, toMonthStr)) {
			return [];
		}

		const firstOfFromMonth = fromMonth.startOf("month");
		const lastOfToMonth = toMonth.endOf("month");

		const families = familyId ? [familyId] : Object.keys(subscriptions);
		let diensten = [];
		for (let i = 0; i < families.length; i++) {
			const familyId = families[i];
			if (!familyId) continue;

			const familyDiensten = getSubscriptionDienstenForFamily(
				familyId,
				subscriptions,
				firstOfFromMonth,
				lastOfToMonth,
				adjustments
			);

			diensten = diensten.concat(familyDiensten);
		}

		return diensten;
	}, [familyId, fromMonthStr, toMonthStr, subscriptions, adjustments]);

	return { loading, diensten };
};
