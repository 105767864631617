import moment from "moment";
import {
	DienstNannyStatus,
	dienstStatusOptions,
	getDienstNannyId,
	getDienstNannyName,
	getDienstStatusColor,
	getDienstStatusFontColor,
	getDienstStatusLabel,
	getDienstStatusLabelComponent,
	getNannyStatusColor,
	getNannyStatusFontColor
} from "../../constants";
import { Dropdown, Form, Icon, Input, Label, Popup } from "semantic-ui-react";
import {
	getLatestReactionForDienst,
	getReactionColourBasedOnTimestamp
} from "../DienstenTab/utils";
import styles from "./index.module.css";

export const columns = [
	{
		name: "status",
		label: "Status",
		getDisplayContent: (dienst) => getDienstStatusLabelComponent(dienst.status),
		getSortLabel: (dienst) => dienst.status,
		filter: (search, dienst) => (search?.length > 0 ? search.includes(dienst.status) : true),
		customFilterComponent: (search, onSearchChange) => (
			<Dropdown
				name="status"
				icon="search"
				iconPosition="left"
				placeholder="Filter..."
				value={search || []}
				onChange={onSearchChange}
				selection
				search
				multiple
				options={dienstStatusOptions}
				className={styles.searchDropdown}
				fluid
				clearable
			/>
		)
	},
	{
		name: "date",
		label: "Datum",
		getSortLabel: (dienst) => dienst.date,
		getDisplayContent: (dienst) => moment(dienst.date, "YYYY-MM-DD").format("LL (dd)")
	},
	{
		name: "startTime",
		label: "Van",
		getDisplayContent: (dienst) => dienst.startTime
	},
	{
		name: "endTime",
		label: "Tot",
		getDisplayContent: (dienst) => dienst.endTime
	},
	{
		name: "familyId",
		label: "Klantnummer",
		getDisplayContent: (dienst) => {
			if (typeof dienst.familyId === "string") return dienst.familyId;
			console.log("INVALID FAMILY", dienst);
			return "";
		},
		filter: (searchText, dienst) => dienst.familyId === searchText.toUpperCase()
	},
	{
		name: "familyName",
		label: "Gezin",
		getDisplayContent: (dienst, familiesById) => familiesById?.[dienst.familyId]?.name || ""
	},
	{
		name: "nanny",
		label: "Nanny",
		getDisplayContent: (dienst, familiesById, knownNanniesPerFamily, reactions, nanniesById) =>
			getDienstNannyName(dienst, nanniesById)
	},
	{
		name: "bekend",
		label: "Bekend",
		getDisplayContent: (dienst, familiesById, knownNanniesPerFamily) => {
			let nannyId = getDienstNannyId(dienst);
			if (!nannyId) return "";
			if (!knownNanniesPerFamily) return "";

			let isBekend = false;
			if (knownNanniesPerFamily[dienst.familyId]) {
				isBekend = knownNanniesPerFamily[dienst.familyId]
					.map((nanny) => nanny.id)
					.includes(nannyId);
			}
			return isBekend ? "Ja" : "Nee";
		}
	},
	// {
	// 	name: "nannies",
	// 	label: "# Nannies",
	// 	getDisplayContent: (dienst, familiesById, knownNanniesPerFamily) => {
	// 		if (dienst.targetAllKnownNannies)
	// 			return knownNanniesPerFamily?.[dienst.familyId]?.length || 0;
	// 		return dienst.targetNannies?.length || 0;
	// 	},
	// 	collapsing: true
	// },
	{
		name: "reactions",
		label: "Reacties",
		getDisplayContent: (dienst, familiesById, knownNanniesPerFamily, reactions) => {
			let nrNannies = 0;
			if (dienst.targetAllKnownNannies) {
				nrNannies = knownNanniesPerFamily?.[dienst.familyId]?.length || 0;
			} else {
				nrNannies = dienst.targetNannies?.length || 0;
			}

			let countsPerStatus = Array(DienstNannyStatus.Last).fill(0);

			// Add nannies that have no reaction yet
			countsPerStatus[DienstNannyStatus.None] = Math.max(
				0,
				nrNannies - (reactions?.[dienst.id]?.length || 0)
			);

			for (let i = 0; i < reactions?.[dienst.id]?.length; i++) {
				const reaction = reactions[dienst.id][i];
				countsPerStatus[reaction.status]++;
			}

			return (
				<div>
					{countsPerStatus.map((count, idx) => (
						<Label
							key={idx}
							circular
							style={{
								backgroundColor: getNannyStatusColor(idx),
								color: getNannyStatusFontColor(idx)
							}}
						>
							{count}
						</Label>
					))}
				</div>
			);
		},
		collapsing: true
	},
	{
		name: "latest_reaction",
		label: "Laatste reactie",
		getSortLabel: (dienst, familiesById, knownNanniesPerFamily, reactions) => {
			const latestReaction = getLatestReactionForDienst(dienst, reactions);
			if (!latestReaction) return "";
			return latestReaction.timestamp;
		},
		getDisplayContent: (dienst, familiesById, knownNanniesPerFamily, reactions) => {
			const latestReaction = getLatestReactionForDienst(dienst, reactions);
			if (!latestReaction) return "";

			const timestampMoment = moment(latestReaction.timestamp);
			const timestampStrFromNow = timestampMoment.fromNow();
			const hoursDiff = moment().diff(timestampMoment, "hours");
			const timestampStr = timestampMoment.format(
				hoursDiff > 8 ? "DD-MM-YYYY HH:mm" : "HH:mm"
			);

			return (
				<span
					style={{
						color: getReactionColourBasedOnTimestamp(latestReaction.timestamp),
						padding: "4px",
						borderRadius: "4px"
					}}
				>
					{`${timestampStrFromNow} (${timestampStr} - ${latestReaction.nannyId})`}
				</span>
			);
		}
	},
	{
		name: "creation_datetime",
		label: "Aangemaakt",
		getDisplayContent: (dienst) => {
			return moment(dienst.creationTimestamp).format("DD-MM-YYYY HH:mm");
		},
		getSortLabel: (dienst) => dienst.creationTimestamp
	},
	{
		name: "internal_remarks",
		label: "Opmerkingen",
		getDisplayContent: (dienst) => dienst?.internalRemarks || "",
	},
	{
		name: "error",
		label: "Fout",
		getDisplayContent: (dienst) =>
			dienst.error ? (
				<Popup
					trigger={<Icon name="exclamation triangle" color="red" />}
					content={dienst.error}
					position="left center"
				/>
			) : null,
		getSortLabel: (dienst) => (dienst.error ? dienst.error : ""),
		disableSearch: true
	}
];
