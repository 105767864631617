import React, { useState } from "react";
import styles from "./SubscriptionDienstenTab.module.css";
import { DienstenTable } from "../DienstenTable";
import { Dimmer, Form, Loader } from "semantic-ui-react";
import MonthDropdown from "../MonthDropdown";
import moment from "moment";
import { CancelDienstButton } from "../CancelDienstButton";
import { columns } from "../DienstenTable/columns";
import { AddEditModal } from "./AddEditModal";
import { useModal } from "../../hooks/useModal";
import { useActualDiensten } from "../../hooks/useActualDiensten";

export const SubscriptionDienstenTab = () => {
	const [fromMonth, setFromMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [toMonth, setToMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [onlyFuture, setOnlyFuture] = useState(true);

	const { actualDiensten: diensten, loading: loadingDiensten } = useActualDiensten({
		familyId: null,
		fromMonthStr: fromMonth,
		toMonthStr: toMonth,
		includeReplaced: true,
		includeDiensten: false,
		includeSubscriptionDiensten: true,
		onlyLocalChanges: true,
		includeCancelled: true,
		onlyFutureDiensten: onlyFuture
	});

	const { modalProps, openModal } = useModal(diensten);

	const loading = loadingDiensten;

	return (
		<div className={styles.container}>
			{loading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
			<AddEditModal {...modalProps} />
			<div className={styles.headerContainer}>
				<div className={styles.selectMonthContainer}>
					<Form>
						<Form.Group inline style={{ marginBottom: 0 }}>
							<MonthDropdown
								label="Van"
								fluid={false}
								month={fromMonth}
								setMonth={setFromMonth}
								monthsAhead={12}
							/>
							<MonthDropdown
								label="Tot"
								fluid={false}
								month={toMonth}
								setMonth={setToMonth}
								monthsAhead={12}
							/>
							<Form.Checkbox
								label="Alleen toekomstige diensten"
								checked={onlyFuture}
								onChange={() => setOnlyFuture(!onlyFuture)}
							/>
						</Form.Group>
					</Form>
				</div>
				<div style={{ display: "flex" }}>
					<CancelDienstButton />
				</div>
			</div>
			<DienstenTable
				diensten={diensten}
				columns={columns.filter(
					(col) => !col.name.includes("reaction") && !col.name.includes("error")
				)}
				deletable={false}
				openModal={openModal}
			/>
		</div>
	);
};
