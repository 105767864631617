import { useMemo } from "react";
import { useDienstenListener } from "./useDiensten";
import { useSubscriptionDiensten } from "./useSubscriptionDiensten";
import { DienstSource, DienstStatus, DienstType, isCancelled } from "../constants";
import moment from "moment";
import { getSubscriptionDienstFromInfo } from "../helpers/getSubscriptionDienstFromInfo";
import { checkDienstenMonth } from "../helpers/checkDienstenMonth";

const getReplacedDienstInformation = (dienst) => {
	let replacedDienst = {};
	if (dienst.replacedDienst) {
		replacedDienst = dienst.replacedDienst;
	} else if (dienst.replacementDienstId) {
		// old way
		let idelems = dienst.replacementDienstId.split("_");
		if (idelems.length >= 3) {
			replacedDienst.nannyId = idelems[2];
			if (replacedDienst.nannyId === "NONANNY") {
				replacedDienst.nannyId = undefined;
			}
		}
	}

	replacedDienst.date = dienst.date;
	replacedDienst.familyId = dienst.familyId;

	if (dienst.startTime && !replacedDienst.startTime) {
		replacedDienst.startTime = dienst.startTime;
	}

	if (dienst.endTime && !replacedDienst.endTime) {
		replacedDienst.endTime = dienst.endTime;
	}

	return replacedDienst;
};

const applyReplacements = (diensten, subscriptionDiensten) => {
	let errors = {};
	let actualDiensten = [];
	for (let i = 0; i < diensten.length; i++) {
		const dienst = diensten[i];

		if (dienst.type === DienstType.Replacement) {
			let replacedDienstInfo = getReplacedDienstInformation(dienst);

			let replacedDienst = getSubscriptionDienstFromInfo(
				subscriptionDiensten,
				replacedDienstInfo,
				errors
			);

			if (replacedDienst) {
				replacedDienst.status = dienst.assignedNannyId
					? DienstStatus.Replaced
					: DienstStatus.LookingForReplacement;
				
				dienst.replacedDienst = replacedDienst;
			} else {
				actualDiensten.push({ ...dienst, error: "Vaste dienst niet gevonden" });
				continue;
			}
		}

		actualDiensten.push(dienst);
	}

	console.log("Error in applying replacements: ", errors);

	return actualDiensten;
};

export const useActualDiensten = ({
	familyId,
	fromMonthStr,
	toMonthStr,
	includeReplaced = false,
	includeDiensten = true,
	includeSubscriptionDiensten = true,
	onlyLocalChanges = false,
	includeCancelled = true,
	onlyFutureDiensten = false
}) => {
	const { loading, diensten } = useDienstenListener(
		familyId,
		fromMonthStr,
		toMonthStr,
		onlyLocalChanges
	);
	const { loading: subscriptionDienstenLoading, diensten: subscriptionDiensten } =
		useSubscriptionDiensten(familyId, fromMonthStr, toMonthStr);

	const actualDiensten = useMemo(() => {
		let actualDiensten = [];

		if (!checkDienstenMonth(diensten, fromMonthStr, toMonthStr)) {
			return actualDiensten;
		}

		if (!checkDienstenMonth(subscriptionDiensten, fromMonthStr, toMonthStr)) {
			return actualDiensten;
		}

		// Add diensten, and search+apply replacements, including errors if it can't find the replaced dienst
		actualDiensten = applyReplacements(diensten, subscriptionDiensten);

		// Add subscription diensten
		actualDiensten = actualDiensten.concat(subscriptionDiensten);

		const today = moment().format("YYYY-MM-DD");
		actualDiensten = actualDiensten.filter((d) => {
			if (!includeDiensten && d.source === DienstSource.Dienst) return false;
			if (!includeSubscriptionDiensten && d.source === DienstSource.Subscription)
				return false;
			if (!includeCancelled && isCancelled(d.status)) return false;
			if (!includeReplaced && d.status === DienstStatus.Replaced) return false;
			if (onlyFutureDiensten && d.date < today) return false;
			return true;
		});

		actualDiensten.sort((a, b) => a.date.localeCompare(b.date));

		return actualDiensten;
	}, [diensten, fromMonthStr, toMonthStr, subscriptionDiensten, includeDiensten, includeSubscriptionDiensten, includeCancelled, includeReplaced, onlyFutureDiensten]);

	return { loading: loading || subscriptionDienstenLoading, actualDiensten };
};
